.megamenu-about {
  /* border: 2px solid red; */
  min-height: 200px;
  width: 100%;
  background-color: transparent;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.mm-about-unit {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  color: var(--text-secondary);
  justify-content: flex-start;
  gap: 10px;
  padding: 15px 20px;
}
.mmau-image {
  height: 55px;
  width: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(135, 207, 235, 0.452);
  background-color: aliceblue;
}
.mmau-image img {
  width: 30px;
}

.mmau-text {
  display: flex;
  flex-direction: column;

  gap: 0px;
  text-align: left;
  padding: 10px 0;
}
.mmau-text-header {
  font-size: 14px;
  font-weight: 600;
  color: black;
}
/* .mmau-text-summary {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
} */
