@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@700&display=swap");
.about-page {
  /* min-height: 37vh; */
  /* border: 2px solid purple; */
  margin-top: 0px;
}

.about-container {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 60vh;
  margin-top: 100px;
  background-color: white;
  color: black;
  /* border: 1px solid green; */
  padding: 10px;
  position: relative;
}

.about-header {
  /* border: 1px solid crimson; */
  padding: 10px;
  color: black;
  font-size: 20px;
  margin-top: 0;
  font-weight: 900;
}
.about-header h1 {
  font-family: "Libre Baskerville", serif;
}
.about-header i {
  padding-bottom: 15px;
  height: 2rem;
}
.about-text {
  /* border: 1px solid red; */
  margin: 5px 0;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
}
.about-text h3 {
  color: var(--primary-color);
  letter-spacing: 1px;
  font-weight: 900;
  line-height: 1.8em;
  font-size: 24px;
  padding: 10px;
}
.skills-text {
  margin-bottom: 40px;
}
.about-text p,
.skills-text p {
  color: var(--dark);
  letter-spacing: 1px;
  line-height: 1.8em;
  padding: 10px;
}
.about-images {
  /* border: 1px solid green; */
  width: 100%;
}
.about-images img {
  width: 100%;

  margin: 5px 0;
}
.about-img-2 {
  display: none;
}
.about-skills {
  /* border: 4px solid steelblue; */
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-start;
  min-height: 400px;
  margin-top: 40px;
}
.skills-text {
  /* border: 1px solid red; */
  text-align: left;
}
.skills-text h3 {
  color: var(--alternate-color);
  letter-spacing: 1px;
  font-weight: 900;
  line-height: 1.8em;
  font-size: 28px;
  padding: 0 45px;
}
.skills-text p {
  padding: 10px;
  padding-bottom: none;
  margin-bottom: 0;
}
.skills-text ul {
  list-style: none;
  padding: 10px;
  padding-top: 0;
  margin-top: 25px;
}
.skills-grid {
  height: 100%;
  min-height: 300px;
  display: grid;
  padding: 10px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  /* border: 1px solid green; */
  background-color: var(--dark);
  border-radius: 10px;
}
.skills-grid i {
  font-size: 58px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: var(--alternate-color);
}
.contact-me {
  margin-top: 40px;
  margin-left: 40px;
}
.skills-text h3,
.skills-text p,
.skills-text ul {
  padding: 0;
}
@media only screen and (min-width: 600px) {
  .about-header {
    padding: 0;
  }
  .about-text h3 {
    padding: 20px 0;
  }
  .about-text {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 20px 0;
    /* border: 1px solid green; */
  }
  .about-images {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .about-img-2 {
    display: block;
  }
  .about-skills {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* border: 1px solid red; */
  }
  .about-header {
    text-align: left;
    font-size: 28px;
  }
  .about-text {
    gap: 80px;
  }
  .skills-text h3,
  .skills-text p,
  .skills-text ul {
    padding: 25px;
  }
}

@media only screen and (min-width: 900px) {
  .skills-text h3,
  .skills-text p,
  .skills-text ul {
    padding: 0 45px;
  }
}
