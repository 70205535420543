@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  scroll-behavior: smooth;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

body::-webkit-scrollbar {
  display: none;
}
a {
  cursor: pointer;
}
:root {
  --max-width: 1300px;
  --alternate-color: #256eff;
  --primary-color: #505ce4;
  --dark: #201c24;
  --theme: #505ce4;
  --secondary-color: #bb342f;
  --alt-color: #5448c8;
  --text-primary: black;
  --text-alt: white;
  --text-secondary: rgba(0, 0, 0, 0.905);
  --box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  --animation: float 2s ease-in-out infinite reverse;
  --animation-slow: float 3s ease-in-out infinite reverse;
}
body {
  font-family: "Poppins", sans-serif;
}
.container {
  width: 100%;
  background-color: transparent;
}
.main-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.section {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.btn {
  border: none;
  width: 100%;
  max-width: 15rem;
  max-height: 4rem;

  font-weight: 700;
  font-family: "Lato", sans-serif;
  border-radius: 5px;

  text-transform: uppercase;
  cursor: pointer;
  box-shadow: var(--box-shadow);
  transition: all 0.3s ease 0s;
  font-size: 12px;
  padding: 10px 14px;
  letter-spacing: 1.5px;

  justify-content: space-evenly;
}
.btn i {
  font-size: 20px;
}
.btn-primary {
  background-color: var(--primary-color);
  color: white;
  border: 1px solid var(--alternate-color);
}
.btn-primary.active {
  background-color: var(--secondary-color);
  color: white;
}
.btn-secondary {
  background-color: transparent;
  color: white;
  padding: 10px 0;
  font-size: 14px;
  letter-spacing: 2px;
  margin: 20px auto;
  border: 1px solid white;
}
.btn-alternate {
  background-color: var(--secondary-color);
  width: 100%;
  color: white;
  padding: 20px 0;
  font-size: 14px;
  letter-spacing: 2px;
}
.btn-alt {
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  animation: var(--animation);
}
.btn-alt i {
  font-size: 20px !important;
  font-weight: 900;
}

.general-header {
  font-size: 38px;
  color: var(--primary-color);
  text-align: center;
  font-weight: 700;
}
.btn-variant {
  border: 0.5px solid var(--alternate-color);
  padding: 15px;
  background-color: #2892d7;
  background-color: var(--alternate-color);
  color: white;
}
.btn-variant i {
  font-size: 12px;
  margin: 0 10px;
}

.btn-new {
  border: 1px solid var(--alt-color);
  background-color: white;
  background-color: #256eff;
  font-weight: 700;
  color: var(--alt-color);
  color: white;
  letter-spacing: 1px;
  padding: 10px 25px;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  letter-spacing: 1.5px;
  font-size: 14px;
}
.btn-admin {
  width: 100%;
  max-width: 350px;
  margin-top: 20px;
  padding: 15px 5px;
  background-color: #256eff;
  font-weight: 700 !important;
  color: white;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

@media only screen and (min-width: 600px) {
  .upt-cta {
  }
  .btn-secondary {
    margin: 0px;
    margin-right: 30px;
  }
  .btn {
    font-size: 14px;
  }
  .btn-secondary {
    padding: 15px 20px;
    letter-spacing: 2.5px;
    font-size: 14px;
    width: 12rem;
  }
}

@media only screen and (min-width: 900px) {
  .btn-admin:hover {
    transform: translateY(-10px);
  }

  .btn {
    font-size: 14px;
    letter-spacing: 2.5px;
    padding: 12px 20px;
    letter-spacing: 2.5px;
  }
  .btn-secondary {
    padding: 15px 20px;
    letter-spacing: 2.5px;
    font-size: 15px;
    font-weight: 700;
    border-radius: 8px;
    width: 12rem;
  }

  .btn:hover {
    box-shadow: 0px 15px 20px rgba(46, 104, 229, 0.4);
    transform: translateY(-7px);
  }
  .btn-variant:hover,
  .btn:hover {
    /* background-color: #2892d7; */
    background-color: aliceblue;
    color: white;
    color: #256eff;
  }
  .btn-new:hover {
    color: var(--alternate-color);
    background: white;
    transform: translateY(-7px);
    border: 1px solid white;
  }
  .btn-secondary:hover {
    color: black;
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translateY(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translateY(0px);
  }
}
@keyframes FadeInLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-0%);
  }
}
@keyframes FadeInRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-0%);
  }
}
