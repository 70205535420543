.ft-services {
  width: 100%;

  max-width: 1300px;
  margin: 50px auto 40px;
  padding: 10px;
}
.ft-services h1 {
  margin: 10px 0 75px;
  color: var(--primary-color);
  text-align: center;
  font-size: 30px;
}

.services-section-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  height: fit-content;
  padding: 10px;
}
.service {
  height: 350px;

  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid var(--primary-color);
  gap: 5px;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 12px;
  border-radius: 4px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  transition: all 200ms ease-in-out;
  background-color: var(--dark);
  color: white;
  position: relative;
}
.service i {
  font-size: 48px;
  color: var(--color-primary1);

  height: 100px;
  width: 100px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 0;
  left: 50%;
}
.service h3 {
  color: var(--color-secondary);
}
.service p {
  letter-spacing: 1px;
  line-height: 1.6em;
}

@media screen and (min-width: 900px) {
  .service:hover {
    transform: translateY(-10px);
  }

  /* .service:hover + .fas {
        display: block;
        color: red;
      } */
}
@media screen and (min-width: 1080px) {
  .services-section-container {
    justify-content: space-between;
  }
  .services-section-container {
    gap: 55px;
  }
}
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-10px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
