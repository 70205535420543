.project-page-container {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  min-height: 60vh;
  margin-top: 100px;
  background-color: white;
  color: black;
  /* border: 1px solid green; */
  padding: 10px;
  position: relative;
}
.pp-upper {
  /* border: 1px solid crimson; */
  color: var(--primary-color);
}
.pp-upper-container {
  display: flex;
  flex-direction: column-reverse;
  /* border: 5px solid orange;
  position: relative; */
}
.pp-upper-circles {
  /* border: 1px solid green; */
  display: flex;

  justify-content: center;
  opacity: 0.25;
  transform: rotate(-5deg);
}
.pp-circle {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.pp-upper-circle-1 {
  background-color: var(--primary-color);
  margin-right: -20px;
}
.pp-upper-circle-2 {
  background-color: var(--dark);
}
.pp-details {
  display: flex;
  justify-content: center;
  gap: 20px;
  text-align: center;
  padding: 0px;
  margin-bottom: 30px;
  margin-top: 10px;
}
.pp-detail-unit {
  display: flex;
  flex-direction: column;
  gap: 7px;
  /* border: 1px solid green; */
  height: 56px;
  justify-content: space-between;
}
.pp-icon-list {
  color: var(--primary-color);
  display: flex;
  gap: 5px;
  justify-content: flex-start;
  align-items: center;
}
.pp-details i {
  font-size: 24px;
}
.pp-details h3 {
  font-size: 15px;
}
.pp-detail-unit {
  font-size: 13px;
}
/* .pp-lower {
  border: 2px solid crimson;
} */
.pp-image {
  /* border: 1px solid crimson; */
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
}
.pp-image img {
  width: 100%;
  /* border: 1px solid green; */
  border-radius: 7px;
}
.pp-summary {
  color: var(--text-secondary);
  letter-spacing: 0.75px;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  grid-area: 10px;
}
.pp-summary h3 {
  color: var(--primary-color);
  font-size: 22px;
}
.pp-md {
  display: none;
}
.pp-lg {
  display: none;
}
.pp-summary-header {
  padding: 0 15px;
}
.pp-summary-text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 15px;
  min-height: 350px;
}
.pp-lower-border {
  height: 2rem;
  width: 50%;
  margin: 150px auto 0;
  /* background-color: var(--primary-color);
  border-radius: 10px; */
  display: flex;
  justify-content: center;
  gap: 20px;
}
.pp-dot {
  background-color: var(--dark);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.pp-up {
  height: 8px;
  width: 8px;
}
.pp-upper-circles-dec {
  position: absolute;
  right: 0;
  top: 50px;
  /* border: 1px solid green; */
  height: 125px;
  width: 125px;
  display: none;

  padding: 10px;
  animation: var(--animation-slow);
  border: none;
  box-shadow: none !important;
}
.col1 {
  background-color: var(--primary-color);
}
.lower-border {
  background-color: var(--primary-color);
  height: 10px;
  width: 5rem;
  border-radius: 10px;
}
.cta-btn-container {
  display: flex;
  justify-content: center;
}
.project-cta-button {
  border: 1px solid rgba(128, 128, 128, 0.753);
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px 5px;
  width: 100%;
  max-width: 10rem;
  display: flex;
  justify-content: center;
  transition: all 500ms ease-in-out;
  background-color: var(--alternate-color);
  box-shadow: var(--box-shadow);
}

.project-cta-button a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  cursor: pointer;
  text-align: right;
  transition: all 300ms ease-in-out;
}
.project-cta-button img {
  width: 30px;
}
@media only screen and (min-width: 600px) {
  .pp-summary {
    margin-top: 20px;
  }

  .pp-upper-circles-dec {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .pp-upper {
    margin: 30px 0 55px;
  }
  .pp-upper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .pp-details {
    justify-content: space-between;
    padding: 20px 5px;
    text-align: left;
  }
  .pp-md {
    display: flex;
  }
  .pp-upper-text {
    text-align: left;
  }

  .pp-summary {
    text-align: center;
    padding: 0 15px;
    /* border: 1px solid green; */

    margin-top: 20px;
  }

  .pp-summary-header {
    padding: 0 25px;
  }
  .pp-summary-text {
    padding: 25px;
  }
}

@media only screen and (min-width: 900px) {
  .pp-upper {
    margin: 50px 0 100px;
  }
  .pp-lg {
    display: flex;
  }
  .pp-summary {
    margin-top: 0px;

    min-height: 500px;
  }
  .pp-summary-header h3 {
    text-align: left;
    font-size: 26px;
    padding-right: 50px;
    text-transform: capitalize;
  }
  .pp-summary-text {
    text-align: left;
    letter-spacing: 1px;
    line-height: 1.8em;
  }
  .pp-lower {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    /* border: 1px solid blue; */
  }
  .project-cta-button:hover {
    background-color: aliceblue;
    color: var(--primary-color) !important;
    transform: translateY(-8px);
  }

  .pp-summary {
    text-align: left;
    padding: 0 15px;
    /* border: 1px solid green; */

    margin-top: 0px;
  }
  .cta-btn-container {
    justify-content: flex-end;
  }
}
