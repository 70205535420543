@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&display=swap");

/*

DO NOT COPY PASTE DIRECTLY

s.case height changed
sscp margin top changed

back


*/
.container-ss {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.single-case-page {
  background: grey;
  font-family: "Merriweather", serif;
}

.s-case {
  min-height: 20vh;
  background-color: var(--primary-color);
  width: 100%;
  padding-top: 100px;
  padding-bottom: 20px;
  background-color: #dce4ef;
}
.s-case-header {
  text-align: left;
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}

.sscp {
  padding-top: 20px;
  min-height: 100vh;
  background: white;
  margin-top: -20px;
}

.case-container-box {
  /* border: 3px solid green; */

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ccb-right {
  /* border: 1px solid crimson; */
  min-height: 100px;
  /* background-color: aqua; */
  width: 20%;
  min-width: 300px;
  display: none;
}
.ccb-left {
  /* border: 1px solid orange; */
  min-height: 100vh;
  /* background-color: grey; */
  width: 100%;
  min-width: 300px;
}

/*side-bar-right css*/
.cc-side-bar {
  border: 2px solid white;
  display: grid;
  grid-template-columns: 1fr;
}
.side-bar-unit {
  padding: 10px;
  border-bottom: 1px solid grey;
  text-align: left;
  background-color: #d6d6d6;
  font-size: 14px;
  cursor: pointer;
  transition: all 500ms ease-in-out;
}
.sbu-header {
  background-color: #006db5;
  color: white;
  font-weight: 700;
  letter-spacing: 1px;
}

.sbu-current {
  border-left: 3px solid crimson;
  background-color: whitesmoke;
}

/*side-bar-left css*/

.ccb-left-container {
  padding: 5px;
}
.ccb-left-accordion {
  /* border: 2px solid red; */
  transition: all 0.5s ease;
  overflow-y: hidden;
}
.ccb-accordion-box {
  border: 2px solid #006db5;
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  background-color: #d6d6d6;
  cursor: pointer;
  transition: all 400ms linear;
  z-index: 4;
}

.ccb-accordion-content {
  text-align: left;
  letter-spacing: 1px;
  line-height: 1.6em;
  transition: all 400ms ease-in-out;
  overflow-y: hidden;
  z-index: -2;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  border: 1px solid green;
}
.hide {
  max-height: 0;
}
.ccb-accordion-summary-box {
  /* border: 1px solid purple; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ccb-accordion-summary-box img {
  height: 300px;
  width: auto;
}
.ccb-accordion-summary {
  display: grid;
  grid-template-columns: 6fr 2fr;
  grid-row-gap: 0px;
  height: 100px;
}
.ccb-accordion-summary > div {
  border: 1px solid grey;
  height: 50px;
  margin: 0;
  padding: 15px 10px;
}
.ccb-accordion-summary :nth-child(odd) {
  background-color: #bbda8e;
  color: #2d4c01;
}

.ccbas-image {
  border: 1px solid grey;
  width: 100%;
  max-width: 300px;
  overflow: hidden;
}
.ccbas-image img {
}

@media only screen and (min-width: 900px) {
  .side-bar-unit:hover {
    background-color: var(--primary-color);
    color: white;
  }
  .ccb-right {
    display: block;
  }
  .ccb-left {
    width: 72%;
  }
}
