.services-container {
  padding: 50px 0;
  width: 100%;
  max-width: 1300px;
  margin: 100px auto 0;
}
.upper-services-container {
  /* border: 1px solid green; */
}
.usc-services {
  /* border: 3px solid steelblue; */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.usc-services-unit {
  border: 2px solid rgba(128, 128, 128, 0.321);
  padding: 15px 20px;
  max-width: 370px;
  margin: 10px;
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.usc-icon {
  /* border: 1px solid grey; */
  display: flex;
  justify-content: space-between;
  margin: 15px 0;
  align-items: center;
}
.usc-icon p {
  /* border: 1px solid blue; */
  background-color: rgba(128, 128, 128, 0.296);
  padding: 0 5px;
  width: 100%;
  max-width: 100px;
}
.usc-text {
  /* border: 1px solid red; */
  width: 100%;
}
.usc-text h3 {
  color: var(--alternate-color);
  letter-spacing: 1.5px;
  margin-bottom: 10px;
}
.service-downward-arrow {
  color: var(--alternate-color);
  margin: 20px 0;
  font-size: 20px;
}
.service-downward-arrow i {
  /* height: 56px;
  width: 56px;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px auto;
  font-size: 28px;
  background-color: var(--alternate-color);

  box-shadow: none !important;
  cursor: pointer; */
}
.lower-container {
  /* border: 1px solid crimson; */
  width: 95%;
  margin: 0 auto;
}
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");

.aboutPage-container {
  font-family: "Fira Sans", sans-serif;
  margin-top: 0px;
}
.header-container-2 {
  padding-top: 50px;
  background-color: #2892d7;
  transition: 800ms ease-in-out;
  overflow-y: hidden;
  margin-bottom: 50px;
}
.header-2 {
  color: var(--color-primary);
  color: var(--color-secondary);

  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.header-2 h1 {
  font-size: 38px;
}
.header-2 p {
  width: 300px;
  margin: 0 auto;
  line-height: 1.8em;
  color: black;
  font-weight: 700;
  letter-spacing: 1px;
}
.header-info-2 {
  display: flex;
}
.header-image-2 {
  width: 100%;
  align-items: center;
  margin: 0 auto;
  overflow-y: hidden;
}
.header-image-2 img {
  height: 150px;
  padding: 10px;
}

.header {
  color: var(--color-primary);
  margin-bottom: 0px;
}
.header h1 {
  font-size: 38px;
  text-align: center;
}
.header p {
  width: 300px;
  margin: 0 auto;
  line-height: 1.8em;
  color: black;
  font-weight: 700;
  letter-spacing: 1px;
}
.about-reasons {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  margin: 80px auto;
  width: 100%;

  height: max-content;
}
.reason {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  max-width: 1300px;
  padding: 10px 20px;
  margin: 20px auto;
  animation: fade-in-right 1700ms ease-in-out;
}
.reason:nth-child(even) {
  flex-direction: column-reverse;
  animation: fade-in-left 1700ms ease-in-out;
}
.reason-image {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
}
.reason-image img {
  width: 100%;
  max-width: 400px;
}
.reason-text {
  width: 100%;

  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.reason-text h2 {
  color: var(--color-primary);
  letter-spacing: 1px;
}
.reason-text p {
  padding: 5px 15px;
  letter-spacing: 1.25px;
  line-height: 1.8em;
  width: 100%;
  max-width: 500px;
}
.about-intro-container {
  padding: 0px 0;
  margin: 80px 0 40px;
  animation: fade-in-left 600ms ease-in-out;
  display: flex;
  flex-direction: column;
  /* border: 1px solid green; */
}

.about-intro {
  text-align: left;
  display: flex;
  flex-direction: column-reverse;
  gap: 40px;
  width: 100%;
  max-width: 1300px;
  margin: 20px auto;
}
.sm2 {
  flex-direction: column-reverse;
}
.about-intro-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
}
.about-intro h3 {
  color: var(--color-primary);
  padding: 0 5px;
}
.about-intro h1 {
  color: var(--color-alternate);
  padding: 0 5px;
}
.about-intro p {
  color: rgba(0, 0, 0, 0.726);
  letter-spacing: 0.85px;
  padding: 0 5px;
}
.about-intro ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  line-height: 1.4em;
  padding: 0 5px;
}
#spe i {
  color: #2892d7;
  font-size: 8px;
  line-height: 100%;
}
.about-intro i {
  color: var(--color-secondary);
  margin-right: 5px;
  font-size: 10px;
}
.btn-about {
  border: 1px solid blue;
  padding: 15px;
}
.btn-about i {
  font-size: 12px;
  margin: 0 10px;
}

.btn-about:hover {
  background-color: #2892d7;
  color: white;
}
.about-intro-image {
  height: auto;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
  min-height: 400px;
  /* max-width: 400px; */
  display: flex;
  justify-content: center;
  padding: 15px;
  position: relative;
  /* border: 1px solid blue; */
  border-radius: 10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow-x: hidden;
}
.img-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #2892d7;
  top: 0;
  opacity: 0.5;
}
.image-circle {
  position: absolute;
  height: 200px;
  width: 200px;
  border-radius: 50%;

  background-color: var(--primary-color);
}
.ic-1,
.ic-2,
.ic-3,
.ic-4 {
  display: none;
}
.ic-1 {
  z-index: 2;
}
.ic-2 {
  height: 400px;
  width: 400px;
  top: 0;
  left: 20%;
  background-color: var(--dark);
  animation: var(--animation-slow);
}
.ic-3 {
  height: 400px;
  width: 400px;
  top: 0;

  background-color: var(--dark);
}
.ic-4 {
  height: 400px;
  width: 400px;
  top: 0;

  background-color: var(--primary-color);
}
.about-intro-image img {
  display: block;
  max-width: 280px;
  max-height: 2800px;
  width: auto;
  height: auto;
  z-index: 3;
  box-shadow: none !important;
  border: none;
}
.services-container {
}
@media only screen and (min-width: 400px) {
  .about-intro-image {
    overflow-y: visible;
  }
  .about-intro-image img {
    max-width: 320px;
    max-height: 320px;
  }
}
@media only screen and (min-width: 600px) {
  .ic-1,
  .ic-2,
  .ic-3,
  .ic-4 {
    display: block;
  }
}

@media only screen and (min-width: 769px) {
  .header-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .reason {
    display: grid;
    grid-template-columns: 1.05fr 2fr;
  }
  .reason:nth-child(even) {
    grid-template-columns: 2fr 1.05fr;
  }
  .reason-text {
    margin: 0 auto;
  }
  .reason-text h2 {
    text-align: left;
    padding: 10px 15px;

    width: 100%;
    max-width: 500px;
  }
  .reason-text p {
    padding: 5px 15px;
    letter-spacing: 1.25px;
    line-height: 1.8em;
    width: 100%;
    max-width: 900px;
    text-align: left;
  }
  .reason-text:nth-child(odd) p {
    max-width: 500px;
  }
  .about-intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .about-intro-text {
    letter-spacing: 1.25px;
    line-height: 1.8em;
    gap: 30px;
  }
  .about-intro-image {
    /* height: 100%; */
  }

  .usc-text {
    /* border: 1px solid green; */
    text-align: left;
    /* padding: 0 10px;
    margin: 0 10px; */
    letter-spacing: 1px;
  }
  .lower-container {
    width: 100%;
  }
}
@media only screen and (min-width: 1100px) {
  .usc-services {
    justify-content: space-between;
    /* display: grid;
    grid-template-columns: 1fr 4fr; */
  }
}
