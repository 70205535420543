.n-m {
  /* border: 1px solid green; */
  height: 80vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* gap: 50px; */
  margin-top: 10px;
}

.nm-link {
  border-bottom: 1px solid rgba(128, 128, 128, 0.403);
  display: flex;
  /* height: 10px; */
  justify-content: center;
  align-items: center;
  padding: 0;

  height: 80px;
}

.n-m a,
.n-m a:visited {
  color: rgba(0, 0, 0, 0.851);
  letter-spacing: 1px;
  font-size: 18px;
}

.nm-link:nth-child(1) {
  /* border: 2px solid red; */
}

#nm1 {
  height: 0px;
  /* background-color: aqua; */
  padding: 0;
  border: none;
}
