.SideMenu {
  /* border: 3px solid green; */
  border-right: 1px solid grey;
  position: fixed;
  height: 100vh;
  margin-top: 0px;
  width: 310px;
  top: 70px;
  bottom: 0;
  padding: 0;
  z-index: 2;
  background-color: white;
  color: goldenrod;
  transform: translateX(-100%);
  transition: 400ms ease-in-out;
  padding-top: 0;
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  padding: 0 0 15vh;
}
.SideMenu::-webkit-scrollbar {
  display: none;
}
.sm-small-show {
  transform: translateX(0%);
}
.sm-small-hide {
  transform: translateX(-100%);
}
.SideMenu-options {
  display: flex;
  flex-direction: column;

  /* border: 1px solid red; */
  height: 120vh;
  padding: 15px 0;
}
.SideMenu-options > div {
  padding-left: 7px;
}

/*Avatar area*/
.SM-profile-info {
  /* border: 2px solid crimson; */
  background-color: white;
  display: none;
  /* justify-content: center; */
}
.SM-close-button i {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border-radius: 50%;
  color: grey;
  margin: 0px auto 30px;
  border: 1px solid grey;
}
.SM-avatar img {
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.SM-details {
  text-align: left;
  color: black;
  font-size: 16px;
  font-weight: 600;
}
/**/

/*Menu area*/
.SM {
  /* border: 3px solid orange; */
  background-color: white;
  color: black;
  text-align: left;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  gap: 10px;
  /* margin: 20px 0 0; */
}
.SM-menu {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 100%;
}
.SM h3 {
  margin-top: 20px;
}
.SM-menu-item {
  display: flex;
  gap: 20px;
  align-items: center;
  cursor: pointer;

  padding: 15px 0px 15px 10px;

  min-width: 280px;
  transition: all 400ms ease-in-out;
  border-bottom: 1px solid rgba(128, 128, 128, 0.232);
  border-top: 1px solid rgba(128, 128, 128, 0.232);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.SM-menu-list i {
  color: grey;
}
.sm-messages {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 95%;
}
.sm-selected {
  border-left: 2px solid crimson;
  background-color: aliceblue;
}
.message-text {
  display: flex;
  gap: 20px;
  align-items: center;
}

.sm-count > div {
  /* border: 1px solid grey; */
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: crimson;
  color: white;
  font-weight: 700;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2);
}

/*Footnotes area*/
.footnotes {
  /* margin-top: 60px; */
}
/***/

/*Footnotes area*/
.footnotes-add {
  /* border: 1px solid grey; */
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  margin-top: 20px;
  border-radius: 50%;
  color: white;
  background-color: crimson;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  transition: all 400ms ease-in-out;
  cursor: pointer;
}

/***/

@media only screen and (min-width: 900px) {
  .SideMenu {
    top: 0;
    transform: translateX(0%);
  }
  .sm-small {
    transform: translateX(0%);
  }
  .SM-profile-info {
    display: flex;
    gap: 20px;
  }
  .sm-small-hide {
    transform: translateX(0%);
  }
  .SM-close-button {
    display: none;
  }
  .SM-menu-item:hover {
    background-color: aliceblue;
  }
  .footnotes-add:hover {
    transform: translateY(-7px);
    background-color: green;
  }
}
