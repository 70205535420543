@import url("https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@400;700&display=swap");
.blogs-page {
  min-height: 50vh;
  padding-top: 100px;
  /* background-color: aquamarine; */
}

.bp-page {
  /* border: 1px solid crimson; */
  /* background-color: grey; */
}

.blog-master-header {
  text-align: left;
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  font-weight: 700;
  color: var(--primary-color);
  font-size: calc(28px + 0.75vw);
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}
.blog-master-header span {
  color: black !important;
}
.blog-master-header img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  margin-bottom: 10px;
}
.blogs-upper-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 95%;
  margin: 0 auto;
  /* border: 2px solid green; */
}
.blogs-overlay {
  /* background: rgba(128, 128, 128, 0.171); */
  background-color: rgba(0, 0, 0, 0.55);
  /* background-color: rgba(0, 0, 0, 0.775); */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transition: all 400ms ease-in-out;
  /* align-items: center; */
  cursor: pointer;
  font-family: "Merriweather Sans", sans-serif !important;
}
.blogs-container {
  height: 250px;
  color: white;
  /* border: 2px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: center;
  gap: 7px;
  padding: 0 5px;
}
.blogs-headliner a {
  width: 100%;
}
.blogs-headliner {
  width: 100%;
  /* border: 2px solid green; */

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  letter-spacing: 0.5px;

  height: calc(450px + 2vw);
}
.blogs-topic {
  background-color: darkgrey;
  width: 100%;
  max-width: 120px;

  color: var(--secondary-color);
  color: var(--alt-color);
  font-weight: 900;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding: 5px;
}
.blogs-header h3 {
  /* font-size: clamp(18px, 1vw + 18px  , ); */
  font-size: 38px;
  /* color: var(--secondary-color);
  color: black; */
}
.blogs-headline-details {
  display: flex;
  gap: 15px;
  margin-top: 7px;
}
.blogs-headline-details small {
  font-size: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
.blogs-features {
  height: 100%;
  width: 100%;
  /* border: 2px solid crimson; */
  /* background-color: bisque; */
  gap: 5px;
  display: flex;
  justify-content: space-between;
  /* gap: 10px; */
  flex-wrap: wrap;
}
.blogs-abstract {
  letter-spacing: 1px;
  font-size: calc(16px + 0.25vw);
}
.bf-header {
  color: var(--alt-color);
  text-align: left;
  margin-top: 0;
  padding-top: 0;
}
.bf-header {
  margin-top: 0;
  padding-top: 0;
}
.blogs-features-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 100%;
  border-top: 3px solid white;
  grid-row-gap: 25px;
  /* border: 1px solid green; */
}
.blogs-features-item:nth-child(1) {
  border-top: none;
}
.blogs-features-item {
  max-width: 350px;

  transition: 400ms ease-in-out;
}
.bf-image {
  height: 100%;
  width: 100%;
  min-width: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.bf-item {
  text-align: left;
  padding: 0px 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* border: 1px solid green; */
  height: 125px;
  cursor: pointer;
}
.bf-item small {
}
.bf-item-header {
  font-weight: 700;
  font-size: clamp(0.8rem, 1.15vw, 1rem);
  /* font-size: 1.15vw; */
  color: black;
}
.blogs-topic-bf {
  color: var(--alt-color);
  font-weight: 700;
  font-size: 0.75rem;
  font-size: 0.7rem;
  font-size: 0.9rem;
  font-size: clamp(0.75rem, 0.7rem, 0.9rem);
}
.bf-item-date {
  font-size: 8px;
}
.blogs-lower-section {
  width: 95%;
  margin: 50px auto;
  /* border: 1px solid crimson; */
}
.blogs-lower-header {
  text-align: left;
  color: var(--alt-color);

  font-weight: 700;
  font-size: calc(22px + 0.25vw);
  margin-bottom: 0;
}

.blogs-lower-list {
  /* border: 1px solid green; */

  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.bl-item {
  /* border: 3px solid blue; */
  width: 95%;
  max-width: 320px;
  text-align: left;
  /* border-bottom: 1px solid grey; */
}
.bl-item-image {
  height: calc(100px + 7vw);
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: all 400ms ease-in-out;
}
.bl-item-text {
  /* border: 1px solid crimson; */
  padding: 0 5px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
}
.bl-item-topic {
  color: var(--alt-color);
  font-weight: 700;
  margin-top: 10px;
  /* border: 1px solid green; */
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: calc(12px + 0.25vw);
}
.bl-item-topic small {
  color: grey;
  width: 100%;
  gap: 5px;

  font-weight: 400;
  display: none;
}
.bl-item-header {
  margin: 5px 0 10px;
  color: black;
  font-size: calc(14px + 0.25vw);
  font-weight: 700;
}
.bl-item-date {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
  color: black;
}
.bl-item-abstract {
  color: rgba(0, 0, 0, 0.8);
  font-size: calc(12px + 0.25vw);
  margin-bottom: 10px;
}
@media only screen and (min-width: 600px) {
  .bl-item-topic small {
    display: flex;
    align-items: center;
  }

  .blogs-overlay {
    justify-content: flex-end;
  }
  .blogs-container {
    padding: 0 10px;
  }
}
@media only screen and (min-width: 900px) {
  .blogs-features-item:hover {
    background-color: aliceblue;
  }
  .upper-blh {
    display: none;
  }

  .blogs-lower-section {
    margin: 30px auto;
    width: 100%;
  }

  .blogs-upper-section {
    display: grid;
    grid-template-columns: 4fr 1.5fr;
    grid-column-gap: 10px;
    /* border: 1px solid red; */
    width: 100%;
  }

  .blogs-container {
    padding: 0 15px;
  }
  .blogs-overlay:hover {
    background: rgba(128, 128, 128, 0.571);
    background-color: rgba(0, 0, 0, 0.45);
  }
  .bf-image img:hover {
    opacity: 0.8;
  }

  .blogs-lower-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .bl-item-image:hover {
    opacity: 0.8;
  }
}
