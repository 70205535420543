.contact-page {
  /* border: 1px solid green; */
  min-height: 100vh;
  position: relative;
  width: 95%;
  margin: 100px auto;
}
.contact-header-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  /* border: 1px solid green; */
}
.contact-page h3 {
  display: flex;
  align-items: center;

  justify-content: space-between;
}
.contact-page-container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
}
.contact-form {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.contact-form-text {
  padding: 0px;
  /* border: 3px solid green; */
  text-align: left;
}
.container-abm {
}
.contact-form-box {
}
.contact-form-text h3 {
  margin-top: 25px;
  color: var(--secondary-color);
  letter-spacing: 1px;
  /* border: 1px solid red; */
  width: 95%;
  padding: 0 10px;
  max-width: 700px;
  font-size: 28px;
}
.contact-form-text p {
  margin-top: 20px;
  width: 100%;
  line-height: 1.9em;
  max-width: 700px;
  width: 95%;
  padding: 0 10px;
}
.contact-form-box {
  /* border: 2px solid red; */
  max-width: 500px;
  width: 100%;
}
.cf-form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px 30px;
  background-color: black;
  color: aliceblue;
  border-radius: 1px;
  width: 95%;
  margin: 30px auto;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
}
.cf-form input,
.cf-form textarea {
  width: 100%;
  padding: 10px 15px;
  border-radius: 2px;
  border: none;
}
.about-page-lower {
  height: 100%;
  max-height: none;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.about-image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.8;
}
.cf-form textarea {
  height: 200px;
}
.cf-image {
}
.cf-image img {
}
.btn-contacts {
  width: 100%;
  /* border: 1px solid green; */
  padding: 15px 25px;
  background-color: var(--primary-color);
  color: white;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  cursor: pointer;
  border-radius: 2px;
  font-size: 16px;
  transition: all 400ms ease-in-out;
}
.cf-form i {
  color: whitesmoke;
  font-size: 38px;
  background-color: var(--primary-color);
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin: -50px auto 0;
}

@media only screen and (min-width: 600px) {
  .contact-form {
    text-align: left;
  }
  .cf-form {
    width: 100%;
  }
  .contact-form-text h3,
  .contact-form-text p {
  }

  .contact-form-box {
  }
}
@media only screen and (min-width: 900px) {
  .contact-form {
  }
  .cf-form {
  }
  .btn-contacts:hover {
    background-color: white;
    color: var(--secondary-color);
    transform: translateY(-10px);
  }
  .contact-form-text h3,
  .contact-form-text p {
    width: 100%;
    padding: 0px;
  }
}
