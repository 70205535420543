.pp-upper {
  /* border: 1px solid crimson; */
  color: var(--primary-color);
}
.pp-upper-container {
  display: flex;
  flex-direction: column-reverse;
  /* border: 5px solid orange;
    position: relative; */
}
.pp-upper-circles {
  /* border: 1px solid green; */
  display: flex;

  justify-content: center;
  opacity: 0.25;
  transform: rotate(-5deg);
}
.pp-circle {
  width: 85px;
  height: 85px;
  border-radius: 50%;
}

.pp-upper-circle-1 {
  background-color: var(--primary-color);
  margin-right: -20px;
}
.pp-upper-circle-2 {
  background-color: var(--dark);
}

.md {
  display: none;
}
.lg {
  display: none;
}
.pp-lower-border {
  height: 2rem;
  width: 50%;
  margin: 150px auto 0;
  /* background-color: var(--primary-color);
    border-radius: 10px; */
  display: flex;
  justify-content: center;
  gap: 20px;
}
.pp-dot {
  background-color: var(--dark);
  height: 10px;
  width: 10px;
  border-radius: 50%;
}
.pp-up {
  height: 8px;
  width: 8px;
}
.pp-upper-circles-dec {
  position: absolute;
  right: 0;
  top: 50px;
  border: 1px solid green;
  height: 125px;
  width: 125px;
  display: none;

  padding: 10px;
  animation: var(--animation-slow);
  border: none;
  box-shadow: none !important;
}
.col1 {
  background-color: var(--primary-color);
}
.lower-border {
  background-color: var(--primary-color);
  height: 10px;
  width: 5rem;
  border-radius: 10px;
}

@media only screen and (min-width: 600px) {
  .pp-upper-circles-dec {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
  }
  .pp-upper {
    margin: 30px 0 55px;
  }
  .pp-upper-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .md {
    display: block;
  }
  .pp-upper-text {
    text-align: left;
  }
}

@media only screen and (min-width: 900px) {
  .pp-upper {
    margin: 50px 0 100px;
  }
  .lg {
    display: block;
  }
}
