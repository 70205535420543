.megamenu-content {
  color: black;
  /* border: 1px solid green; */
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  padding-top: 10px;
  transition: all 500ms ease-in-out;
}

.primary-block {
  /* border: 1px solid blue; */
  text-align: left;
  display: flex;
  flex-direction: column;
}
.primary-block-header {
  color: rgba(0, 0, 0, 0.786);
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.primary-list {
  display: flex;
  flex-direction: column;
  letter-spacing: 1px;
  gap: 0px;
  /* border: 1px solid green; */
  padding-bottom: 20px;
}
.primary-list-item {
  padding: 5px 5px 10px;
  width: 85%;
  border-radius: 4px;
}
.primary-list-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.774);
}
.primary-list-text {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 5px;
}
.primary-list-text h5 {
  color: rgba(0, 0, 0, 0.899) !important;
}
.primary-list-item {
  display: flex;
  gap: 10px;
}
.primary-list-item i {
  font-size: 30px;
}
.sub-block {
  /* border: 1px solid green; */
  text-align: left;
  font-size: 14px;
}
.sub-block-header {
  font-size: 15px;
}
.sub-list {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: rgba(0, 0, 0, 0.582);
}
.sub-list-item {
  width: 80%;
}
.Blog-reminder {
  width: 100%;
  /* border: 1px solid green; */
  text-align: left;
}
.Blog-reminder img {
  height: 100%;
  max-height: 120px;
  width: auto;
}
.br-lower {
  display: flex;
  /* border: 3px solid steelblue; */
}
.blog-text {
  font-size: 13px;
  line-height: 1.8em;
  text-align: left;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.blog-text p {
  letter-spacing: 0.5px;
  font-size: 13px;
  font-weight: 500;
}

.blog-btn {
  width: 80%;
  max-width: 10rem;
  background-color: aliceblue;
  border: 1px solid var(--primary-color);
  padding: 7px 5px;
  letter-spacing: 0.25px;
  transition: all 500ms ease-in-out;
}
.blog-image {
  padding: 10px 0px;
}
.sb-md,
.sb-lg,
.sb-xl {
  display: none;
}

@media only screen and (min-width: 600px) {
  .sb-md {
    display: block;
  }

  .megamenu-content {
    grid-template-columns: 1.5fr 1fr 1fr;
  }

  .primary-list-item {
    cursor: pointer;
    transition: all 300ms ease-in-out;
  }
  .primary-list-item:hover {
    background-color: aliceblue;
  }
  .sub-list-item {
    cursor: pointer;
    transition: all 300ms ease-in-out;
  }
  .sub-list-item:hover {
    background-color: aliceblue;
  }
  .blog-btn {
    cursor: pointer;
  }
  .blog-btn:hover {
    background-color: var(--primary-color);
    color: aliceblue;
  }
}

@media only screen and (min-width: 769px) {
  .megamenu-content {
    grid-template-columns: 1.5fr 1fr 1fr 1fr;
  }
  .sb-lg {
    display: block;
  }
}
@media only screen and (min-width: 1080px) {
  .megamenu-content {
    grid-template-columns: 1.5fr 1fr 1fr 1fr 1.75fr;
  }
  .sb-xl {
    display: block;
  }
}
