.landing {
  width: 98%;
  margin: 0 auto;
  background-color: transparent;
  position: relative;
  /* border: 1px solid green; */
}

.landing a {
  color: white;
  transition: all 500ms ease-in-out;
}
.landing a:visited {
  color: white;
}
.underlay {
  background-color: var(--dark);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 67%;
  z-index: 0;
  border-radius: 10px;
}
.landing-container {
  width: 95%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0px;
  display: grid;
  grid-template-rows: 4.5fr 7fr;
  height: 90vh;
}
.landing-top {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: FadeInLeft 1.5s ease-in-out;
  /* border: 1px solid green; */
}
.landing-top img {
  width: 100%;
  max-width: 250px;
  animation: var(--animation);
  border: none !important;
  box-shadow: none !important;
}
.landing-top h1 {
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 38px;
}
.span-text {
  background-color: var(--primary-color);
  padding: 1px 5px;
  border-radius: 4px;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  color: white;
}

.landing-bottom {
  padding: 0;
  color: aliceblue;
  /* display: flex;
  justify-content: flex-start;
  align-items: center; */

  z-index: 3;

  animation: FadeInRight 1.5s ease-in-out;
  /* border: 1px solid red; */
}
.buttons-cta {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 90px;
  align-items: center;
  height: 100%;
  /* border: 1px solid green; */
}
.buttons-cta button {
  min-width: 16rem;
}
.landing-bottom-cta {
  /* border: 1px solid green; */
}
.landing-bottom-cta i {
  font-size: 14px;
}
.btn-landing {
  border: 1px solid white;
  background-color: white;
  color: black;
  width: 80%;
  width: 12rem;
  padding: 15px 0;
  font-size: 16px;
}
.lb-services {
  display: none;
}
.landing-bottom-upper {
  display: block;
  /* border: 1px solid green; */
  width: 100%;
}
.landing-bottom-lower {
  display: none;
}
.lb-circle p {
  font-size: 13px;
}
.landing-bottom-cta h1,
.landing-bottom-cta p {
  display: none;
}
.landing-bottom-cta h1 {
  font-size: 40px;
  margin-bottom: 10px;
}
.landing-bottom-cta p {
  margin-bottom: 50px;
}
.button-cta {
  display: none;
}
.btn-container {
  /* border: 2px solid orange; */
  text-align: center;
  display: flex;
  letter-spacing: 1px;
  flex-direction: column;
  gap: 30px;
}
.btn-hero {
  padding: 10px 0px;
  width: 100%;
  max-width: 12rem;
  border-radius: 15px;
  background-color: transparent;
  color: white;
  letter-spacing: 1px;
  font-size: 16px;
  border: 1px solid white;
  margin: 0 auto;
  cursor: pointer;
  transition: 500ms ease-in-out;
}

#circle-1 {
  animation: 3.6s FadeIV-1 ease-in-out;
}
#circle-2 {
  animation: 3.6s FadeIV-2 ease-in-out;
}
#circle-3 {
  animation: 3.6s FadeIV-3 ease-in-out;
}
@media only screen and (min-width: 600px) {
  .landing a:hover {
    color: black;
  }

  .landing-container {
    grid-template-rows: 4fr 6fr;
    min-height: 50vh;
  }
  .landing-header h1 {
    font-size: 59px;
  }
  .landing-top img {
    display: none;
  }
  .landing-bottom {
    display: grid;
    grid-template-rows: 1fr;
    /* border: 4px solid orange; */
  }
  .landing-bottom-upper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    gap: 10px;
    padding: 0px 20px;
  }

  .lb-circle {
    border: 1px solid grey;
    padding: 25px;
    border-radius: 50%;
    /* height: 300px;
    width: 300px; */
    background-color: var(--dark);
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    text-align: left;
  }
  .lb-circle h1 {
    font-size: 20px;
  }
  .lb-circle h3 {
    text-align: left;
  }
  .second {
    background-color: var(--theme);
    border: none;
    margin: 0 -20px;
    z-index: 9;
  }
  .buttons-cta {
    display: none;
  }
  .landing-bottom-cta h1,
  .landing-bottom-cta p {
    display: block;
  }

  .button-cta {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .lb-circle {
    height: 155px;
    width: 155px;
  }
}
@media only screen and (max-width: 899px) {
  .lb-circle {
    height: 185px;
    width: 185px;
  }
}

@media only screen and (min-width: 769px) {
}

@media only screen and (min-width: 900px) {
  .btn-hero {
    margin: 0;
  }

  .btn-container {
    text-align: left;
  }

  .btn-contact {
    display: none;
  }

  .lb-services {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 300px;
  }

  .landing-bottom-lower {
    display: flex;

    justify-content: space-evenly;
    font-size: 28px;
    padding: 10px 0;
  }

  .lb-circle {
    height: 230px;
    width: 230px;
  }
  .button-cta {
    display: block;
    width: 80%;

    margin: 0 auto;
  }
  .lb-circle h1 {
    font-size: 28px;
  }
  .landing-bottom-upper {
    display: flex;
    flex-direction: row;
  }
  .btn-hero:hover {
    background-color: white;
    color: black !important;
  }
  .btn-hero:hover + .btn-a {
    color: red !important;
  }
}
@media only screen and (min-width: 950px) {
  .lb-circle {
    height: 235px;
    width: 235px;
  }
}
@media only screen and (min-width: 1140px) {
  .lb-circle {
    height: 255px;
    width: 255px;
  }
}
@media only screen and (min-width: 500px) {
  .header-breaker {
    display: none;
  }
}

@keyframes FadeIntoView {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeIntoView2 {
}

@keyframes FadeIV-1 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes FadeIV-2 {
  0% {
    opacity: 0;
  }
  33% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes FadeIV-3 {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
