.menuTopBar {
  position: fixed;
  top: 0;
  height: 80px;
  width: 100%;
  background-color: #256eff;
  z-index: 5;
  border-bottom: 1px solid grey;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  font-size: 24px;
  transition: all 400ms ease-in-out;
}
.dashboard-container {
  background-color: white;

  margin-top: 100px;
}
.dashboard-side {
  display: none;
}
.dashboard-main {
  padding: 0 calc(2vw - 5px);
}
/*Header-block*/
.dashboard-header-block {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.dashboard-header-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.progress-bar-container {
  /* border: 1px solid green; */
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
}
.progress-bar {
  width: 50%;
  border: 1px solid grey;
  background-color: aliceblue;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.dash-progress {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: crimson;
  left: 0;
}
/*Upper-block*/
.upper-block {
  display: flex;

  gap: 10px;
  margin: 10px;
  justify-content: space-between;
}
.upper-block.alternate,
.upper-block.messages {
  justify-content: flex-start;
}
.upper-block.alternate > div,
.upper-block.messages > div {
  width: 100%;
}

.single-block {
  width: 25%;
  max-width: 300px;
  /* border: 1px solid green; */
  height: calc(150px + 4vw);
  color: white;
  border-radius: 10px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  padding: 20px 10px 10px;
  transition: all 400ms ease-in-out;
  cursor: pointer;
  overflow: hidden;
  position: relative;
}
#messages {
  background-color: #f26d8c;
  background-color: #505ce4;
}

#cases {
  background-color: #bcacf4;
  background-color: #256eff;
}
#requests {
  background-color: #256eff;
  background-color: #f04770;
}
#articles {
  background-color: #14bdeb;
}

.curve {
  position: absolute;
  width: 300px;
  height: 175px;
}
.first-curve {
  border-radius: 0% 90% 0 0;
  background: #f04770;

  bottom: 0;
  left: 0;
}
.second-curve {
  border-radius: 0 0 0 100%;
  background-color: #ac98ef;
  top: 0;
  right: 0;
}
.third-curve {
  border-radius: 0 0 100% 0;
  background-color: #6d8ac4;
  top: 0;
  right: 0;
}
.fourth-curve {
  border-radius: 100% 0 0 0;
  bottom: 0;
  left: 0;
  background-color: #4cad93;
}
.content {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.content-icons {
  width: 100%;
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.content-icons > div {
  height: calc(40px + 1vh);
  width: calc(40px + 1vh);
  border-radius: 50%;
  border: 2px solid white;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-icons img {
  width: calc(15px + 0.75vw);
}
.content-avatars {
  display: flex;
  /* border: 1px solid red; */
  padding: 0 10px;
}
.content-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  /* align-items: center; */
  /* border: 1px solid blue; */
  height: 50%;
  font-size: calc(12px + 0.35vw);
  font-weight: 700;
}
.content-message small {
  font-weight: 500;
  display: none;
}
.content-avatars img {
  border: 2px solid white;
  width: calc(15px + 1.2vw);
  height: calc(15px + 1.2vw);
  border-radius: 50%;
  margin-left: -10%;
}
.content-avatars img:nth-child(1) {
  margin-left: 0;
}

#articles {
  background-color: #06d6a0;
}

/**/
/*Overview area lower-block css*/

.lower-block {
  margin-top: 30px;
}
.lower-block img {
}
.lower-upper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 10px;
}
.lower-upper-sort {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.lower-upper-sort > div {
  padding-left: 7px;
  padding-right: 3px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding-bottom: 7px;
  font-size: calc(18px + 0.1vw);
  color: grey;
  transition: all 300ms smooth;
}
.selected-option {
  border-bottom: 3px solid #505ce4;
  color: #505ce4 !important;
  font-weight: 700;
}
.lower-bottom {
}
.bottom-overview-header {
  text-align: left;
  font-size: calc(18px + 0.1vw);
  color: grey;
  padding: 0 10px;
}
.overview-box-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.overview-box-item {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid rgba(128, 128, 128, 0.15);
}
.overview-box-item h3,
.overview-box-item small {
  margin: 0;
  padding: 0;
}
.overview-item-images {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid green; */
  min-width: calc(100px + 1vw);
}
.overview-item-images img {
  width: calc(30px + 1vw);
  height: calc(30px + 1vw);
  border-radius: 50%;
  border: 2px solid white;
  margin-left: 0%;
}
.overview-item-images {
  display: none;
}
.overview-item-images img:nth-child(1) {
  margin: 0;
}
.overview-item-main {
  display: flex;
  gap: 10px;
  /* border: 1px solid green; */
}
.overview-item-icon,
.checkbox {
  background-color: #256eff;
  display: flex;
  justify-content: center;

  border-radius: 5px;
  padding: 2px;
  width: calc(12px + 1vw);
  height: calc(12px + 1vw);
}
.overview-item-main small {
  color: grey;
}
.overview-item-icon img {
}
.dashboard-search {
  display: none;
}
.overview-item-details {
  /* border: 1px solid green; */
  padding: 0 5px;
  width: 100%;
}
/*Overview dashboard-side*/
.ds-upper {
  text-align: left;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.459);
}
.ds-side-main {
  display: flex;
  justify-content: space-between;
}
.ds-mid-level {
  text-align: left;
  display: flex;
  flex-direction: column;
}
.stats-header {
  /* border: 1px solid crimson; */
}
.stats-container {
  margin: 30px 0 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.stat-unit {
  display: flex;
  flex-direction: column;
}
.stat-unit h5,
.stats-header small {
  color: grey;
  font-weight: 700;
}
.stat-users {
  display: flex;
}
.stat-users img:nth-child(1) {
  margin: 0;
}
.stat-users img {
  margin-left: -10%;
}

/* Lower section*/

.ds-lower {
  border-top: 1px solid rgba(128, 128, 128, 0.533);
  padding-top: 25px;
}

.ds-form {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding: 5px;
}
.ds-form small {
  color: grey;
  font-weight: 700;
}
.ds-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ds-header i {
  background-color: rgba(128, 128, 128, 0.2);
  padding: 5px;
  border-radius: 5px;
}
.ds-form input {
  width: 100%;
  max-width: 350px;
  padding: 10px 5px;
  border: 1px solid rgba(128, 128, 128, 0.6);
  border-radius: 5px;
}
/**/
.btn-admin {
  width: 100%;
  max-width: 350px;
  margin-top: 20px;
  padding: 15px 5px;
  background-color: #256eff;
  font-weight: 700 !important;
  color: white;
  letter-spacing: 1px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 400ms ease-in-out;
}

@media only screen and (min-width: 400px) {
  .overview-item-images {
    display: flex;
    justify-content: space-between;
    gap: calc(1vw - 15px);
  }
  .overview-item-images small {
    font-size: calc(9px + 0.25vw);
  }
}
@media only screen and (min-width: 900px) {
  .btn-admin:hover {
    transform: translateY(-10px);
  }

  .overview-box-item:hover {
    background-color: aliceblue;
  }

  .menuTopBar {
    display: none;
  }

  .dashboard-button {
    display: none;
  }
  .dashboard-container {
    width: calc(100% - 310px);
    transform: translateX(310px);
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 0;
  }
  .single-block:hover {
    transform: translateY(-15px);
  }
  .dashboard-search {
    display: block;
  }
}
@media only screen and (min-width: 1100px) {
  .dashboard-container {
    grid-template-columns: 5fr 2fr;
  }
  /* .upper-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 5px;
  } */

  .dashboard-side {
    display: block;
    min-width: 200px;
    height: 95%;
    background-color: aliceblue;
    border-left: 1px solid grey;
    position: sticky;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 0;
    transition: 400ms all ease-in-out;
  }
  .dashboard-side > div {
    /* border: 1px solid red; */
    min-height: 200px;
    width: 90%;
    margin: 0 auto;
  }
  .content-message small {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .upper-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .single-block {
    width: 100%;
  }
}
