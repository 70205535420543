.navbar-modal {
  width: 100%;
  height: 100vh;
  background-color: #f0f8ffe8;
  color: black;
  position: fixed;
  z-index: 9;
  font-size: 130;
  padding-top: 0px;
  transition: all 600ms ease-in-out;
  transform: translateY(-120%);
  background-color: white;
  /* border: 4px solid saddlebrown; */
  top: 80px;
}

.navbar-modal-container {
  height: 100%;
  position: relative;
  /* border: 1px solid crimson; */
  height: 700px;
  /* background-color: aqua; */
}
.navbar-menu {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 60px;
  padding: 40px 50px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1.5px;
  text-align: left;
  /* border: 1px solid green; */
}
.navbar-modal-selection {
  /* border: 2px solid blue; */
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  background-color: white;
  text-align: left;
  padding: 40px 50px;
  transition: all 600ms ease-in-out;
}
.nms-active {
  transform: translateX(0%);
}
.portfolio-nms-container {
  /* border: 1px solid green; */
  display: flex;
  flex-direction: column;
  height: 500px;
  justify-content: space-around;
  font-size: 22px;
  color: var(--text-secondary);
  font-weight: 700;
}
.portfolio-nms-container i {
  color: var(--primary-color);
}
.navbar-modal-selection h3 {
  color: var(--primary-color);
  font-size: 28px;
}
.pnms-unit {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
.navbar-item {
  /* border: 1px solid crimson; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbar-sm-selection {
  padding-left: 10px;
  color: var(--primary-color);
  /* border: 1px solid green; */
  min-height: 300px;
  transition: all 500ms ease-in-out;
}
.nms-header {
  display: flex;
  gap: 20px;
  color: var(--primary-color);
  align-items: center;
  margin-bottom: 40px;
}
.nms-header i {
  font-size: 24px;
}
@media only screen and (min-width: 600px) {
  .navbar-modal {
    display: none;
  }
}
