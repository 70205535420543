.dropDownMenu-Container {
  position: absolute;
  height: fit-content;
  background-color: none;
  width: 100%;
  top: 100%;
  /* left: 0%; */
  padding: 2px 0 0;
  overflow-y: hidden;
  display: none;
  /* border: none !important;
  background-color: aqua; */
  background-color: transparent;
  transition: all 500ms ease-in-out;
}
.dropDownMenu-Container.active {
  top: 140%;
}
.ddm-container {
  height: 100%;
  width: 100%;
  transition: all 500ms ease-in-out;
}

.dropdown-menu {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px;
  gap: 20px;
  z-index: 10;
  /* background-color: var(--primary-color);
  background: rgb(37, 110, 255);
  background: linear-gradient(
    90deg,
    rgba(37, 110, 255, 0.95) 0%,

    rgba(53, 111, 173, 0.95) 100%
  ); */
  background-color: white;

  padding: 10px 35px;
  border-radius: 3px;
  transition: all 300ms ease-in-out;
  animation: slide-down 300ms ease-in-out;
  color: white;
  border: 1px solid rgba(128, 128, 128, 0.2);
  /* border-top: none; */
  box-shadow: var(--box-shadow);
}
.dropdown-menu.active {
  color: white;
}

.dd-mm {
  display: flex;
}
.navbar-item {
  color: var(--primary-color);
  color: var(--text-secondary);

  /* border: 1px solid black;
  width: 100%; */
}
.dropdown-menu-section {
  /* border: 1px solid red; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0px;
  border-right: 0.5px solid white;
}
.dropdown-menu-list {
  /* border: 1px solid crimson; */
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.dropdown-menu-list-item {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 70%;
  border-radius: 10px;
  padding: 5px;
  transition: all 500ms ease-in-out;
}
.dropdown-menu-list-item {
  color: white !important;
  font-size: 14px;
}
.dropdown-menu-section h3 {
  text-align: left;
  letter-spacing: 4px;
  margin-bottom: 13px;
}

.dd-blog {
  display: none;
  border: none;
}
.xs {
  border-right: none;
}
@media only screen and (min-width: 600px) {
  .dropDownMenu-Container {
    display: block;
  }
}

@media only screen and (min-width: 900px) {
  .dd-blog {
    display: block;

    text-align: left;
    padding-top: 0;
    padding: 0 20px;
    width: 80%;
  }
  .dd-blog img {
    width: 70%;
    border-radius: 10px;
    margin-top: 5px;
  }
  .dd-blog h3 {
    color: white;
    letter-spacing: 0.75px;
    font-weight: 700;
    font-size: 16px;
    width: 70%;
    margin-bottom: 0;
  }
  .dd-blog-cta {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: white;
  }
  .dd-blog-cta a {
    color: white;
    text-decoration: underline;
    font-weight: 500;
    cursor: pointer;
  }
  .dd-blog-cta a:visited {
    color: aliceblue;
  }
  .xs {
    border-right: 1px solid white;
  }
  .dropdown-menu-list-item:hover {
    background-color: rgba(255, 255, 255, 0.963);
    cursor: pointer;
    color: black !important;
  }
}

@keyframes slide-down {
  0% {
    /* transform: translateY(-100%); */
    opacity: 0;
  }
  100% {
    /* transform: translateY(0); */
    opacity: 1;
  }
}
