.pitch {
  background-color: var(--dark);
  width: 98%;
  margin: 0 auto;
  border-radius: 10px;
}
.pitch-container {
  width: 98%;
  max-width: 1300px;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  gap: 150px;

  color: white;
}
.upper-pitch {
  /* border: 1px solid crimson; */
  display: flex;
  flex-direction: column;
  gap: 25px;
  padding: 10px;
  margin-top: -100px;
}
.up-text-section {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 20px;
  /* border: 1px solid white; */
  padding: 20px;
  color: white;
  margin-top: -100px;
}

.up-image-section {
  /* border: 1px solid green; */
  overflow-x: hidden;
  display: none;
}
.up-image-section img {
  animation: var(--animation);
  box-shadow: none !important;
  overflow-x: hidden;
  width: 300px;
  height: auto;
}
.up-text-section h1 {
  color: var(--primary-color);
}
.up-text-section p {
  color: white;
  padding: 10px 30px 0px 0px;
  line-height: 1.8em;
  letter-spacing: 1px;
}
.upt-cta {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.upt-cta button {
}

.lower-pitch {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px;
  padding-bottom: 0px;
  margin-top: -110px;
}
.lower-pitch h1 {
  color: var(--primary-color);
}
.lp-text p {
  color: white;
  padding: 25px 0;
  line-height: 1.8em;
}
.lp-logos {
  /* border: 1px solid green; */
  display: flex;
  justify-content: space-evenly;
  font-size: 38px;
  padding: 10px;
}
.pitch-header {
  /* border: 1px solid green; */
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: -20px;
  margin-bottom: 15px;
  padding: 0 10px;
}
.pitch-circle {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  font-size: 28px;
  border: 2px solid steelblue;
}
.lp-tagline h2 {
  text-align: left;
  padding: 0;
  margin: 0;
  /* border: 1px solid green; */
}
.btn-pitch {
  background-color: transparent;
  padding: 15px 25px;
  width: 100%;
  min-width: 130px;
  border-radius: 10px;
  border: 1px solid white;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 700;
  transition: 400ms ease-in-out;
}

@media only screen and (min-width: 600px) {
  .up-image-section {
    display: block;
  }
  .pitch-header {
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
  }
  .pitch-header h1 {
    font-size: 40px;
  }
  .pitch-circle {
    height: 150px;
    width: 150px;
    font-size: 34px;
  }
  .pitch-container {
    margin: 100px auto 40px;
  }

  .upper-pitch {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .upt-cta {
    flex-direction: row;
  }
  .up-text-section {
    text-align: left;
  }
  .lower-pitch {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .lp-text p {
    text-align: left;
    letter-spacing: 1px;
  }
  .lower-pitch h1 {
    text-align: left;
  }
  .lp-logos {
    grid-column: span 2;
  }
}
