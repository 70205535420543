@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;300;400;500;600;700&display=swap");

.login-form-container {
  height: 700px;
}
.login-form {
  width: 95%;
  max-width: 600px;
  margin: 100px auto;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  height: 400px;
  background-color: grey;
  font-family: "Fira Sans", sans-serif;
  color: white;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.4);
  animation: pop-down 800ms ease-in-out;
}

.login-form > div {
  background-color: white;
  padding: 5px;
  color: grey;

  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  border-radius: 5px;
}

.login-form input {
  width: 90%;
  max-width: 20rem;
  height: 2rem;
  border: none;
  padding: 0 6px;
  font-size: 14px;
}
.login-form button {
  margin: 0 auto;
}

@keyframes pop-down {
  0% {
    opacity: 0;
    transform: translateY(-20%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
