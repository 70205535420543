.work-page {
  padding-top: 0px;
  margin-top: 50px;
}
.banner-box {
  /* border: 2px solid orange; */
  width: 100%;
  height: 75vh;
  display: flex;
  margin-bottom: 100px;
  flex-direction: column;
  gap: 100px;
  justify-content: center;
  align-items: center;
  /* background-color: grey; */
  /* background: url("https://cdn.pixabay.com/photo/2016/11/29/08/41/apple-1868496_960_720.jpg")
    no-repeat center center/cover; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.banner-text {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 0px;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  align-items: center;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.838);
}

.bgd-overlay {
  background-color: grey;
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.test {
  margin-top: 150px;
  height: 70vh;
  /* background-color: aqua; */
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.test-unit {
  height: 500px;
  width: 180px;
  background-color: blue;
  border-radius: 10px;
  background: url("https://images.unsplash.com/photo-1552308995-2baac1ad5490?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80");
  /* background-size: c; */
  background-attachment: fixed;
}
.test-unit:nth-child(2) {
  margin-top: 4%;
}
