.article-page {
  min-height: 100vh;
  background-color: white;
  padding-top: calc(70px + 10vh);
}

.right,
.left {
  display: none;
}
.blog-article-container {
  /* border: 2px solid crimson; */
  width: 95%;
  margin: 0 auto;
}

@media only screen and (min-width: 1100px) {
  .left {
    display: block;
    left: 0;
    width: calc(160px + 5vw);
  }
}
@media only screen and (min-width: 900px) {
  .sidenav {
    height: 100%; /* Full-height: remove this if you want "auto" height */
    /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    /* Stay on top */
    top: 100px; /* Stay at the top */

    background-color: white; /* Black */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 0px;
    border: 1px solid rgba(128, 128, 128, 0.25);
    border-top: none;

    z-index: 1;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 100px;
  }

  .sidenav::-webkit-scrollbar,
  .article-page::-webkit-scrollbar {
    display: none;
  }

  .right {
    right: 0;
    width: calc(160px + 14vw);
    display: flex;
    flex-direction: column;
  }
  .blog-article-container {
    padding-right: calc(160px + 14vw);
    max-width: 1300px;
  }

  .sr-header {
    margin: 20px 0 40px;
  }
  .sr-search {
    width: 100%;
    margin: 20px auto;
  }
  .search-input {
    display: flex;
    border: 1px solid grey;
    width: 100%;
    /* max-width: 300px; */

    border-radius: 10px;
    padding: 10px;
    gap: 5px;
    align-items: center;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .search-input input {
    border: none;
    background-color: transparent;
  }

  .sr-bio {
    /* border: 1px solid green; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 2vh;
  }
  .sr-bio-name {
    height: 100px;
    display: flex;
    gap: 5px;
  }
  .sr-bio-text {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .sr-bio img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
  }
  .sr-bio-links {
    display: flex;
    justify-content: space-between;
    color: var(--secondary-color);
  }
  .sr-bio-links button {
    padding: 10px 15px;
    letter-spacing: 1px;
    border-radius: 5px;
    border: 1px solid rgba(128, 128, 128, 0.5);
    box-shadow: var(--box-shadow);
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
    transition: all 0.3s ease-in-out;
  }

  .sr-bio-links button:hover {
    transform: translateY(-5px);
  }

  .sr-suggestions {
    text-align: left;
  }
  .sr-suggestion-text {
    max-width: 250px;
  }
  .sr-suggestion {
    display: grid;
    grid-template-columns: 4fr 1fr;
    color: var(--primary-color);
    margin-bottom: 25px;
    gap: 5px;
  }
  .sr-suggestion small {
    color: grey;
  }
  .suggestions-image {
    height: 12vh;
    width: 14vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* border: 1px solid rgba(128, 128, 128, 0.5); */
  }
}

@media only screen and (min-width: 300px) {
}
