.overview-page {
  min-height: 50vh;
  margin-top: 0px;
  padding: 0px 0;
  /* background-color: black; */
  /* border: 1px solid red; */
}

.main-container {
  /* border: 1px solid blue; */
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
}
.overview-project-showcase {
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0px;
  width: 90%;
  margin: 20px auto;
  /* height: calc(700px - 2vh); */
  position: relative;
  background: url("https://dlr5c2ow1z4cp.cloudfront.net/wp-content/uploads/2022/04/12162516/traveltech1a.jpg");
}

.overview-project-gallery {
  /* width: 95%; */
}

.op-showcase {
  /* border: 1px solid steelblue; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  box-shadow: var(--box-shadow);
}

.sc-main {
  max-width: none;
  /* position: absolute; */
  width: 100%;
  height: 100%;
  color: black;
  background-color: rgba(0, 0, 0, 0.879);
  background-color: #230820da;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.op-header h2 {
  font-size: 30px;
  text-transform: capitalize;
  color: var(--alt-color);
  margin-bottom: 10px;
  color: goldenrod;
  letter-spacing: 1px;
}
.op-showcase-text {
  width: 100%;
  max-width: 600px;
  text-align: left;
  /* border: 2px solid white; */
}

.op-showcase-image {
  width: 100%;
  min-width: 300px;
  max-width: 500px;
  /* border: 2px solid white; */
}

.op-showcase-image img {
  width: 100%;
  max-width: 400px;
}
.op-cta {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 25px;
  /* display: grid;
  grid-template-columns: 1fr 1fr; */
  /* border: 1px solid orange; */
  margin: 25px 0;
  color: var(--secondary-color);
  color: white;
  max-width: 300px;
}
.op-cta img {
  width: 50px;
  /* border: 1px solid green; */
}
.op-cta-text {
  /* border: 2px solid green; */
  display: flex;
  /* align-items: center; */
  gap: 10px;
  margin: 10px 0;
  /* justify-content: center; */
  align-items: center;
  /* justify-content: ; */
  width: 100%;
  max-width: 150px;
}
.op-btn {
  display: flex;
  justify-content: left;
}
/* .ops-overlay {
  background-color: black;
  background: url("https://dlr5c2ow1z4cp.cloudfront.net/wp-content/uploads/2022/04/12162516/traveltech1a.jpg");

  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
} */
.overview-project {
  /* background: url("https://dlr5c2ow1z4cp.cloudfront.net/wp-content/uploads/2022/04/12162516/traveltech1a.jpg");
   */
  overflow: hidden;
  box-shadow: var(--box-shadow);
  width: 90%;
  padding: 0;
}
.overview-projects-lower {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
.ovp-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  align-items: center;
  /* border: 1px solid green; */
  border: 1px solid steelblue;
  color: black;
  background-color: rgba(240, 248, 255, 0.758);
  background-color: rgba(0, 0, 0, 0.85);
  /* background-color: #230820da; */
  color: white;
  text-align: left;
  padding: 20px 0px;
  width: 100%;
  /* max-width: 600px; */
  min-height: 400px;
  box-shadow: var(--box-shadow);
}
.ovp-text {
  width: 100%;
  max-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-top: 20px;
}
.ovp-text h2 {
  color: goldenrod;
  text-align: left;
  text-transform: capitalize;
}
.ovp-image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
  height: 200px;
}

.ovp-image img {
  width: 90%;
  max-width: 300px;
}

@media only screen and (min-width: 900px) {
  .op-btn,
  .op-cta {
    justify-content: flex-start;
  }
  .overview-project-showcase,
  .overview-project {
    width: 100%;
  }

  .overview-projects-lower {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
