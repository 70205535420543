.navbar {
  height: 60px;
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: white;
  transition: all 300ms ease-in-out;
  z-index: 10;
}
.navbar a {
  color: black;
}
.navbar.active a {
  color: var(--primary-color);
}
.navbar-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  max-width: 1300px;
  margin: 0 auto;
  /* border: 1px solid green; */
  position: relative;
}
.navbar-large {
  display: none;
}
.navigation {
  display: flex;
  gap: 10px;
  /* border: 1px solid green; */
}
.navigation-link {
  /* border: 1px solid crimson; */
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}
.navigation-link {
  position: relative;
  transition: all 300ms ease-in-out;
}
.navigation-link i {
  transition: all 300ms ease-in-out;
}
.navigation-link:hover {
  color: var(--primary-color);
}
.navigation-link :after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: var(--primary-color);
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.navigation-link p:hover:after {
  width: 100%;
  left: 0;
}
.navigation-link i {
  padding-top: 3px;
  font-size: 10px;
}
.drop-down-content {
  position: absolute;
  top: 47px;
  height: 270px;
  width: calc(350px + 16vw);
  /* border: 1px solid green; */
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.nav-socials {
  display: flex;
  gap: 25px;
  color: var(--primary-color);
}
.drop-down-content.active {
  background-color: var(--primary-color);

  background-color: aliceblue;
  color: black;
}
.navbar.active {
  background-color: var(--primary-color);
  background-color: rgba(240, 248, 255, 0.904);
  background-color: white;
  color: white;
  color: var(--primary-color);
  height: 80px;
  z-index: 10;
  border-bottom: 1px solid grey;
}
.new-dropdown {
  position: absolute;
  border: 1px solid red;
  height: 600px;
  width: 100%;
  background-color: rgba(0, 255, 255, 0.185);
  right: 0;
  top: 0px;
}

@media only screen and (min-width: 600px) {
  .navbar-large {
    display: block;
    position: relative;
  }
  .navbar-menu-sm {
    display: none;
  }
}
